import React, { useState, useEffect, useRef } from "react";
import { nominatimUrl, tileserverUrl } from "../../../../constants";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import mapboxgl from "mapbox-gl";
import { IconButton } from "../../atoms/IconButton";
import { Button, TextField } from "../../../../components";

import * as S from "./styles";
import { IProps } from "./types";

export const MapBuilder: React.FC<IProps> = ({
  handleViewportChange,
  handleNameChange,
  handleDestinationChange,
  customization,
  style,
}: IProps) => {
  const {
    parameters: { viewport },
  } = customization;

  const [mapInstance, setMapInstance] = useState();
  const [mapRef, setMapRef] = useState();
  const [mapImg, setMapImg] = useState();
  const [mapData, setMapData] = useState({
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  const [destinations, setDestinations] = useState([""]);
  const [destinationCoords, setDestinationCoords] = useState({});

  // Load any images that we will be displaying on the map such as markers
  useEffect(() => {
    if (mapRef && !mapInstance) {
      window.scrollTo(0, 0);
      setMapInstance(mapRef.getMap());
    }
  });

  /*
   * Called after the map has been loaded to pre-load other features such as
   * marker images and layers
   */
  const onMapLoad = () => {
    const map = mapRef.getMap();
    map.loadImage(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Map_marker.svg/512px-Map_marker.svg.png",
      (error, image) => {
        map.addImage("cat", image);
      },
    );

    map.addSource("markers", mapData);

    map.addLayer({
      id: `points`,
      type: "symbol",
      source: "markers",
      layout: {
        "icon-image": "cat",
        "icon-size": 0.1,
        "icon-offset": [0, 10],
      },
    });
    window.scrollTo(0, 0);
  };

  const handleRender = () => {
    setMapImg(mapInstance.getCanvas().toDataURL());
  };

  const addDestination = () => {
    setDestinations([...destinations, ""]);
  };

  const removeDestination = index => {
    if (destinations) {
      setDestinations(destinations.filter((v, i) => i !== index && v !== ""));

      addLayers(destinations.filter((v, i) => i !== index && v !== ""));
    }
  };

  const geocodeDestination = destination => {
    if (destination in destinationCoords) {
      return new Promise(resolve => resolve(destinationCoords[destination]));
    }
    return fetch(`${nominatimUrl}${destination}`).then(res => {
      setDestinationCoords({
        ...destinationCoords,
        [destination]: res.clone().json(),
      });
      return res.json();
    });
  };

  const addLayers = toAdd => {
    const toFetch = toAdd ? toAdd : destinations;
    console.log(toFetch);
    Promise.all(toFetch.map(dest => geocodeDestination(dest))).then(
      geocodedDest => {
        const featureData = { ...mapData.data, features: [] };
        // keep track of coords alone to use when we fit bounds
        const coords = [];
        geocodedDest.map((data, i) => {
          const coord = [parseFloat(data[0].lon), parseFloat(data[0].lat)];
          coords.push(coord);
          featureData.features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coord,
            },
            properties: {
              osmPlaceId: data[0].place_id,
              osmId: data[0].osm_id,
              osmDisplayName: data[0].display_name,
              osmClass: data[0].class,
              osmType: data[0].type,
              user_label: destinations[i],
            },
          });
        });

        handleDestinationChange(featureData);

        setMapData({ data: featureData });
        mapInstance.getSource("markers").setData(featureData);

        if (coords.length > 1) {
          var bounds = new mapboxgl.LngLatBounds();

          coords.forEach(coord => {
            bounds.extend(coord);
          });

          const { longitude, latitude, zoom } = new WebMercatorViewport(
            viewport,
          ).fitBounds(
            [
              [bounds._ne.lng, bounds._ne.lat],
              [bounds._sw.lng, bounds._sw.lat],
            ],
            { padding: 40 },
          );

          handleViewportChange({
            ...viewport,
            longitude,
            latitude,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
            zoom,
          });
        } else {
          mapInstance.panTo(coords[0]);
        }
      },
    );
  };

  return (
    <div className="product-page__product__builder">
      <div className="product-page__product__gallery">
        <ReactMapGL
          ref={map => setMapRef(map)}
          mapStyle={`${tileserverUrl}/styles/${style}/style.json`}
          {...viewport}
          onLoad={() => onMapLoad()}
          onViewportChange={(viewport: any) => handleViewportChange(viewport)}
        />
      </div>
      <ol className="product-page__product__customization">
        <li>
          <h4>About Your Map</h4>
          <TextField
            type="text"
            autoFocus
            label="Name your adventure"
            value={customization.parameters.name || ""}
            styleType="grey"
            onChange={e => handleNameChange(e.target.value)}
          />
        </li>
        <li>
          <h4>Your Destinations</h4>
          {destinations.map((destination, i) => (
            <div key={i} className="product-page__input">
              <TextField
                type="text"
                autoFocus={i > 0 && i === destinations.length - 1}
                label="Enter a destination"
                value={destination || ""}
                styleType="grey"
                onChange={e => {
                  let dest = [...destinations];
                  dest[i] = e.target.value;
                  setDestinations(dest);
                }}
                onBlur={e => {
                  if (
                    i === destinations.length - 1 &&
                    e.target.value &&
                    e.target.value.length > 0
                  ) {
                    addLayers();
                    setDestinations([...destinations, ""]);
                  }
                }}
              />
              <div className="product-page__input__actions">
                <Button
                  tabIndex="-1"
                  className="input-remove"
                  onClick={() => removeDestination(i)}
                >
                  remove
                </Button>
              </div>
            </div>
          ))}
          <Button secondary onClick={() => addDestination()}>
            Add Stop
          </Button>
        </li>
        <li>
          <Button color="secondary" onClick={() => handleRender()}>
            Render
          </Button>
        </li>
      </ol>
      {mapImg && <img src={mapImg} />}
    </div>
  );
};
