import {
  mediumScreen,
  smallScreen,
} from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import { Trans } from "@lingui/react";
import * as React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import {
  MenuDropdown,
  Offline,
  Online,
  OverlayContext,
  OverlayTheme,
  OverlayType,
} from "..";
import { maybe } from "../../core/utils";
import { baseUrl } from "../../routes";
import { CartContext } from "../CartProvider/context";
import { UserContext } from "../User/context";
import NavDropdown from "./NavDropdown";
import { TypedMainMenuQuery } from "./queries";

import cartImg from "../../images/cart.svg";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import logoImg from "../../images/LogoMark.svg";
import userImg from "../../images/user.svg";

const MainMenu: React.FC = () => (
  <OverlayContext.Consumer>
    {overlayContext => (
      <nav className="main-menu" id="header">
        <Link to="/">
          <ReactSVG path={logoImg} className="main-menu__logo" />
          <span className="main-menu__title">CartoArto</span>
        </Link>
        <div className="main-menu__left"></div>

        <div className="main-menu__center"></div>

        <div className="main-menu__right">
          <ul>
            <Online>
              <CartContext.Consumer>
                {cart => (
                  <li
                    className="main-menu__icon main-menu__cart"
                    onClick={() => {
                      overlayContext.show(OverlayType.cart, OverlayTheme.right);
                    }}
                  >
                    <ReactSVG path={cartImg} />
                    {cart.getQuantity() > 0 ? (
                      <span className="main-menu__cart__quantity">
                        {cart.getQuantity()}
                      </span>
                    ) : null}
                  </li>
                )}
              </CartContext.Consumer>
            </Online>
            <Offline>
              <li className="main-menu__offline">
                <Media
                  query={{ minWidth: mediumScreen }}
                  render={() => <span>Offline</span>}
                />
              </li>
            </Offline>
          </ul>
        </div>
      </nav>
    )}
  </OverlayContext.Consumer>
);

export default MainMenu;
