import { smallScreen } from "../../globalStyles/scss/variables.scss";

import classNames from "classnames";
import * as React from "react";
import Media from "react-media";
import { MapBuilder } from "@components/organisms/MapBuilder";

import { TextField } from "../../components";
import { RichTextContent } from "@components/atoms";
import {
  CachedImage,
  CachedThumbnail,
  ProductDescription,
} from "../../components";
import { CartContext } from "../../components/CartProvider/context";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils";
import GalleryCarousel from "./GalleryCarousel";
import { ProductDetails_product } from "./types/ProductDetails";
import { TypedCreateCustomizationMutation } from "../../checkout/queries";

import { structuredData } from "../../core/SEO/Product/structuredData";

import noPhotoImg from "../../images/no-photo.svg";

class Page extends React.Component<
  { product: ProductDetails_product },
  {
    customization: {
      name: string;
      product: string;
      parameters: {
        viewport: {
          width: number;
          height: number;
          latitude: number;
          longitude: number;
          zoom: number;
        };
        features: any;
      };
    };
  }
> {
  productGallery: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      customization: {
        name: "",
        product: this.props.product.id,
        parameters: {
          viewport: {
            width: 600,
            height: (600 * 14) / 11,
            latitude: 0.0,
            longitude: 0.0,
            zoom: 0,
            preserveDrawingBuffer: true,
          },
          features: {},
        },
      },
    };
  }

  get showCarousel() {
    return this.props.product.images.length > 1;
  }

  handleViewportChange = viewport => {
    this.setState({
      customization: {
        ...this.state.customization,
        parameters: { ...this.state.customization.parameters, viewport },
      },
    });
  };

  /*
   * Handle updates to the title changing
   */
  handleNameChange = name => {
    this.setState({
      customization: {
        ...this.state.customization,
        parameters: { ...this.state.customization.parameters, name },
      },
    });
  };

  /*
   * Saves the geoJSON feature layer to customization parameters
   */
  handleDestinationChange = features => {
    this.setState({
      customization: {
        ...this.state.customization,
        parameters: {
          ...this.state.customization.parameters,
          features,
        },
      },
    });
  };

  render() {
    const { product } = this.props;
    const cartContextConsumer = (
      <CartContext.Consumer>
        {cart => (
          <TypedCreateCustomizationMutation>
            {(createCustomization, { loading }) => {
              // Wraps the cart context action with a mutation to create the
              // customization server side first
              const addToCart = (variantId, quantity) => {
                // Need to make sure all parameters values are string
                const params = this.state.customization.parameters;
                createCustomization({
                  variables: {
                    customizationInput: {
                      ...this.state.customization,
                      parameters: JSON.stringify(params),
                    },
                  },
                }).then(
                  ({
                    data: {
                      productCustomizationCreate: {
                        errors,
                        productCustomization,
                      },
                    },
                  }) => {
                    // Now add it to the  cart context
                    cart.add(variantId, productCustomization.id, quantity);
                  },
                );
              };
              return (
                <ProductDescription
                  product={product}
                  productVariants={product.variants}
                  addToCart={addToCart}
                >
                  <RichTextContent descriptionJson={product.descriptionJson} />
                </ProductDescription>
              );
            }}
          </TypedCreateCustomizationMutation>
        )}
      </CartContext.Consumer>
    );
    return (
      <div className="product-page">
        <div className="container">
          <div className="product-page__product">
            {/* Add script here */}
            <script className="structured-data-list" type="application/ld+json">
              {structuredData(product)}
            </script>

            {/*  */}
            <Media query={{ maxWidth: smallScreen }}>
              {matches =>
                matches ? (
                  <>
                    <GalleryCarousel images={product.images} />
                    <div className="product-page__product__info">
                      {cartContextConsumer}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="product-description">
                      <h3>{product.name}</h3>
                    </div>
                    <MapBuilder
                      handleViewportChange={viewport =>
                        this.handleViewportChange(viewport)
                      }
                      handleNameChange={name => this.handleNameChange(name)}
                      handleDestinationChange={this.handleDestinationChange}
                      customization={this.state.customization}
                      style={this.props.match.params.slug}
                    />
                    {cartContextConsumer}
                    <div className="product-description__about">
                      <h4>Description</h4>
                      <RichTextContent
                        descriptionJson={product.descriptionJson}
                      />
                    </div>
                  </>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
