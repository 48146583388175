import * as React from "react";
import { Route as ReactRoute } from "react-router-dom";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";

export const Route: React.FC = ({ children, ...props }) => (
  <Amplitude
    eventProperties={inheritedProps => ({
      ...inheritedProps,
      scope: [...inheritedProps.scope, "route"],
      url: props.computedMatch.url,
    })}
    debounceInterval={1000}
  >
    {({ logEvent }) => (
      <>
        {logEvent("page view")}
        {children}
        <ReactRoute {...props}></ReactRoute>
      </>
    )}
  </Amplitude>
);
