export const icons = {
  arrow_back: [
    {
      d:
        "M4.965 17.333h25.684c0.353 0 0.689-0.14 0.939-0.39s0.393-0.59 0.393-0.943-0.143-0.693-0.393-0.943c-0.25-0.25-0.586-0.39-0.939-0.39h-25.691l6.613-5.653c0.266-0.23 0.433-0.557 0.463-0.91 0.027-0.35-0.087-0.7-0.316-0.97-0.23-0.267-0.556-0.433-0.909-0.463-0.35-0.027-0.699 0.087-0.969 0.317l-9.354 8c-0.27 0.23-0.436 0.557-0.463 0.91-0.027 0.35 0.087 0.7 0.313 0.97 0.076 0.088 0.162 0.165 0.257 0.23l9.247 7.917c0.27 0.23 0.616 0.343 0.969 0.317s0.679-0.193 0.909-0.463c0.23-0.27 0.343-0.617 0.316-0.97s-0.193-0.68-0.463-0.91l-6.606-5.653z",
      fill: "#949494",
    },
  ],
  edit: [
    {
      d:
        "M15.001 9.478l-3.1 3.104c-1.142 1.144-2.291 2.287-3.426 3.444-0.268 0.274-0.47 0.64-0.548 1-0.411 1.889-0.809 3.777-1.207 5.666l-0.222 1.046c-0.104 0.51 0.033 1.020 0.378 1.366 0.268 0.268 0.64 0.418 1.031 0.418 0.111 0 0.222-0.013 0.333-0.033l1.149-0.242c1.827-0.386 3.661-0.778 5.488-1.157 0.424-0.092 0.783-0.281 1.090-0.588 5.13-5.143 10.259-10.28 15.388-15.411 0.378-0.379 0.587-0.83 0.64-1.386 0.006-0.091 0-0.183-0.020-0.268-0.033-0.131-0.059-0.268-0.091-0.399-0.072-0.333-0.15-0.712-0.307-1.078-0.959-2.196-2.552-3.764-4.731-4.647-0.437-0.176-0.9-0.235-1.305-0.288l-0.111-0.013c-0.587-0.072-1.122 0.124-1.586 0.595-2.937 2.954-5.893 5.915-8.843 8.869zM25.214 1.59c0.013 0 0.020 0 0.033 0l0.111 0.013c0.339 0.039 0.653 0.078 0.901 0.183 1.775 0.719 3.080 2 3.87 3.804 0.091 0.209 0.15 0.477 0.209 0.758 0.020 0.105 0.046 0.209 0.065 0.314-0.026 0.118-0.072 0.196-0.163 0.281-5.136 5.13-10.265 10.274-15.395 15.411-0.085 0.085-0.163 0.124-0.281 0.15-1.834 0.386-3.661 0.771-5.495 1.157l-0.966 0.203 0.183-0.856c0.398-1.882 0.796-3.771 1.201-5.653 0.013-0.059 0.065-0.15 0.124-0.216 1.136-1.15 2.271-2.294 3.413-3.431l3.1-3.104c2.956-2.961 5.913-5.921 8.862-8.888 0.124-0.105 0.183-0.124 0.228-0.124z M2.539 7.126h11.394c0.444 0 0.803-0.359 0.803-0.804s-0.359-0.804-0.803-0.804h-11.394c-1.403 0-2.539 1.144-2.539 2.542v21.398c0 1.405 1.142 2.542 2.539 2.542h21.36c1.403 0 2.539-1.144 2.539-2.542v-10.947c0-0.444-0.359-0.804-0.803-0.804s-0.803 0.359-0.803 0.804v10.947c0 0.516-0.424 0.941-0.94 0.941h-21.353c-0.516 0-0.94-0.425-0.94-0.941v-21.391c0-0.516 0.424-0.941 0.94-0.941z",
      fill: "#323232",
    },
  ],
  plus: [
    {
      d:
        "M18 14v-12c0-1.104-0.896-2-2-2s-2 0.896-2 2v12h-12c-1.104 0-2 0.896-2 2s0.896 2 2 2h12v12c0 1.104 0.896 2 2 2s2-0.896 2-2v-12h12c1.104 0 2-0.896 2-2s-0.896-2-2-2h-12z",
      fill: "#21125E",
    },
  ],
  social_facebook: [
    {
      d:
        "M16 0c-8.822 0-16 7.178-16 16s7.178 16 16 16c8.822 0 16-7.178 16-16s-7.177-16-16-16zM19.979 16.563h-2.603c0 4.159 0 9.278 0 9.278h-3.857c0 0 0-5.070 0-9.278h-1.834v-3.279h1.834v-2.121c0-1.519 0.722-3.893 3.893-3.893l2.858 0.011v3.183c0 0-1.737 0-2.075 0s-0.818 0.169-0.818 0.893v1.927h2.939l-0.337 3.279z",
      fill: "#21125e",
    },
  ],
  social_instagram: [
    {
      d:
        "M16 32c-8.836 0-16-7.163-16-16s7.164-16 16-16c8.836 0 16 7.163 16 16s-7.164 16-16 16zM22.609 5.451h-13.307c-2.124 0-3.852 1.728-3.852 3.852v13.307c0 2.124 1.728 3.852 3.852 3.852h13.307c2.124 0 3.852-1.728 3.852-3.852v-13.307c0-2.123-1.728-3.852-3.852-3.852zM10.512 7.335c-1.752 0-3.177 1.425-3.177 3.177v10.976c0 1.752 1.425 3.177 3.177 3.177h10.976c1.752 0 3.177-1.425 3.177-3.177v-10.976c0-1.752-1.425-3.177-3.177-3.177h-10.976zM16 21.705c-3.145 0-5.705-2.559-5.705-5.705s2.559-5.705 5.705-5.705c3.146 0 5.705 2.559 5.705 5.705s-2.559 5.705-5.705 5.705zM21.888 11.475c-0.745 0-1.35-0.606-1.35-1.35 0-0.273 0.081-0.526 0.22-0.737 0.065-0.1 0.143-0.19 0.232-0.269 0.239-0.213 0.554-0.344 0.899-0.344 0.568 0 1.055 0.352 1.253 0.85 0.062 0.155 0.096 0.323 0.096 0.5 0 0.744-0.605 1.35-1.35 1.35zM12.705 15.999c0-1.815 1.479-3.293 3.295-3.293s3.294 1.478 3.294 3.293c0 1.817-1.477 3.295-3.294 3.295s-3.295-1.478-3.295-3.295z",
      fill: "#21125e",
    },
  ],
  social_twitter: [
    {
      d:
        "M16 0c-8.822 0-16 7.178-16 16s7.178 16 16 16c8.822 0 16-7.178 16-16s-7.177-16-16-16zM23.138 12.338c0.007 0.159 0.011 0.318 0.011 0.478 0 4.866-3.703 10.476-10.479 10.476-2.080 0-4.016-0.608-5.645-1.653 0.288 0.034 0.581 0.052 0.878 0.052 1.726 0 3.313-0.589 4.574-1.576-1.611-0.030-2.972-1.094-3.44-2.558 0.224 0.043 0.456 0.066 0.692 0.066 0.336 0 0.662-0.044 0.971-0.128-1.685-0.338-2.954-1.826-2.954-3.611 0-0.015 0-0.032 0.001-0.046 0.496 0.275 1.064 0.441 1.667 0.46-0.987-0.659-1.638-1.787-1.638-3.065 0-0.675 0.181-1.308 0.498-1.852 1.816 2.229 4.53 3.694 7.59 3.849-0.063-0.27-0.095-0.55-0.095-0.84 0-2.033 1.649-3.683 3.682-3.683 1.060 0 2.015 0.447 2.688 1.163 0.84-0.165 1.626-0.47 2.339-0.894-0.277 0.86-0.859 1.582-1.622 2.038 0.746-0.089 1.457-0.286 2.115-0.579-0.491 0.737-1.116 1.386-1.835 1.904z",
      fill: "#21125e",
    },
  ],
  social_youtube: [
    {
      d:
        "M13.084 19.507c2.401-1.245 4.781-2.479 7.183-3.724-2.409-1.257-4.788-2.498-7.183-3.747 0 2.499 0 4.973 0 7.471z",
      fill: "#21125e",
    },
    {
      d:
        "M16 0c-8.836 0-16 7.163-16 16s7.164 16 16 16 16-7.163 16-16c0-8.837-7.164-16-16-16zM26.902 21.341c-0.277 1.201-1.26 2.088-2.442 2.22-2.801 0.313-5.636 0.315-8.46 0.313-2.824 0.002-5.659 0-8.461-0.313-1.183-0.132-2.165-1.018-2.441-2.22-0.394-1.711-0.394-3.579-0.394-5.341s0.005-3.63 0.398-5.341c0.276-1.201 1.258-2.088 2.441-2.22 2.802-0.313 5.638-0.315 8.461-0.313 2.823-0.002 5.659 0 8.459 0.313 1.183 0.132 2.166 1.018 2.442 2.22 0.394 1.711 0.391 3.579 0.391 5.341s-0.001 3.63-0.395 5.341z",
      fill: "#21125e",
    },
  ],
  trash: [
    {
      d:
        "M28.496 5.327h-6.236v-1.017c0-1.818-1.479-3.297-3.297-3.297h-5.928c-1.818 0-3.297 1.479-3.297 3.297v1.017h-6.236c-0.462 0-0.832 0.37-0.832 0.832s0.37 0.832 0.832 0.832h1.504v19.546c0 2.452 1.996 4.449 4.449 4.449h13.088c2.452 0 4.449-1.997 4.449-4.449v-19.546h1.504c0.462 0 0.832-0.37 0.832-0.832s-0.37-0.832-0.832-0.832zM11.403 4.311c0-0.9 0.733-1.633 1.633-1.633h5.928c0.9 0 1.633 0.733 1.633 1.633v1.017h-9.194v-1.017zM25.329 26.537c0 1.534-1.251 2.785-2.785 2.785h-13.088c-1.534 0-2.785-1.251-2.785-2.785v-19.546h18.665v19.546h-0.006z M16 26.341c0.462 0 0.832-0.37 0.832-0.832v-14.702c0-0.462-0.37-0.832-0.832-0.832s-0.832 0.37-0.832 0.832v14.696c0 0.462 0.37 0.838 0.832 0.838z M10.571 25.423c0.462 0 0.832-0.37 0.832-0.832v-12.872c0-0.462-0.37-0.832-0.832-0.832s-0.832 0.37-0.832 0.832v12.872c0 0.462 0.376 0.832 0.832 0.832z M21.428 25.423c0.462 0 0.832-0.37 0.832-0.832v-12.872c0-0.462-0.37-0.832-0.832-0.832s-0.832 0.37-0.832 0.832v12.872c0 0.462 0.37 0.832 0.832 0.832z",
      fill: "#323232",
    },
  ],
  x: [
    {
      d:
        "M16 12.8l-12.8-12.8-3.2 3.2 12.8 12.8-12.8 12.8 3.2 3.2 12.8-12.8 12.8 12.8 3.2-3.2-12.8-12.8 12.8-12.8-3.2-3.2-12.8 12.8z",
      fill: "#c4c4c4",
    },
  ],
};
