import urljoin from "url-join";

export const apiUrl = urljoin(process.env.BACKEND_URL || "/", "/graphql/");
export const tileserverUrl =
  process.env.TILESERVER_URL || "http://localhost:8099";
export const nominatimUrl =
  process.env.NOMINATIM_URL ||
  `https://nominatim.openstreetmap.org/search?format=json&limit=1&q=`;
export const amplitudeKey=
  process.env.AMPLITUDE_KEY || null;
export const serviceWorkerTimeout =
  parseInt(process.env.SERVICE_WORKER_TIMEOUT, 10) || 60 * 1000;
