import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import undraw from "../../images/undraw_map.svg";
import { Button, Loader, ProductsFeatured } from "../../components";
import { ProductsList_categories } from "../../core/types/saleor";
import { generateCategoryUrl } from "../../core/utils";
import {
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./types/ProductsList";

import { structuredData } from "../../core/SEO/Homepage/structuredData";

import noPhotoImg from "../../images/no-photo.svg";
import heroImg from "../../images/hero.png";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => (
  <>
    <script className="structured-data-list" type="application/ld+json">
      {structuredData(shop)}
    </script>
    <div className="home-page__hero">
      <div className="home-page__hero__image">
        <img src={heroImg} alt="map on wall" />
      </div>
      <div className="home-page__hero-text">
        <div className="home-page__hero__title">
          <h1>Personalized Maps</h1>
          <h3>Personalized Maps for Adventurers</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        <div className="home-page__hero-action">
          <Button>Personalize Yours</Button>
          <Button secondary>How it Works</Button>
        </div>
      </div>
    </div>
    <ProductsFeatured title={"Products"} />
  </>
);

export default Page;
