import "./scss/index.scss";

import * as React from "react";
import { Link } from "react-router-dom";

import { generateProductUrl, maybe } from "../../core/utils";
import { Button, CachedThumbnail } from "..";
import { BasicProductFields } from "../../views/Product/types/BasicProductFields";

import noPhotoImg from "../../images/no-photo.svg";

export interface Product extends BasicProductFields {
  category?: {
    id: string;
    name: string;
  };
  price: {
    localized: string;
  };
}

interface ProductListItemProps {
  product: Product;
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product }) => {
  const { price, category } = product;
  const descriptionJson = product.descriptionJson
    ? JSON.parse(product.descriptionJson)
    : "";
  const description = descriptionJson.blocks
    ? descriptionJson.blocks[0].text
    : "";
  return (
    <div className="product-list-item">
      <div className="product-list-item__image">
        <CachedThumbnail source={product}>
          <img src={noPhotoImg} alt={product.thumbnail.alt} />
        </CachedThumbnail>
      </div>
      <div className="product-list-item__meta">
        <Link to={generateProductUrl(product.id, product.name)}>
          <h4 className="product-list-item__title">{product.name}</h4>
        </Link>
        <p>{description}</p>
        <p className="product-list-item__price">
          Starting at {price.localized}
        </p>
        <Link to={generateProductUrl(product.id, product.name)}>
          <Button secondary>Customize Yours</Button>
        </Link>
      </div>
    </div>
  );
};

export default ProductListItem;
