import * as React from "react";
import { Switch } from "react-router-dom";
import { Route } from "../routes/Route";

import { Elements, StripeProvider } from "react-stripe-elements";

import { NotFound } from "../components";
import {
  Billing,
  CheckoutDispatcher,
  Payment,
  Review,
  Shipping,
  ShippingOptions,
} from "./views";

export const baseUrl = "/checkout/";
export const shippingAddressUrl = `${baseUrl}shipping-address/:token?/`;
export const shippingOptionsUrl = `${baseUrl}shipping-options/:token?/`;
export const billingUrl = `${baseUrl}billing-address/:token?/`;
export const paymentUrl = `${baseUrl}payment/:token?/`;
export const reviewUrl = `${baseUrl}review/:token?/`;

export const Routes: React.FC = () => (
  <StripeProvider apiKey="pk_test_wmDaIB9mpY3QruTXr344OhLu">
    <Elements>
      <Switch>
        <Route exact path={baseUrl} component={CheckoutDispatcher} />
        <Route path={shippingAddressUrl} component={Shipping} />
        <Route path={shippingOptionsUrl} component={ShippingOptions} />
        <Route path={billingUrl} component={Billing} />
        <Route path={paymentUrl} component={Payment} />
        <Route path={reviewUrl} component={Review} />
        <Route component={NotFound} />
      </Switch>
    </Elements>
  </StripeProvider>
);
